































































import Vue from 'vue';
import AccountApi from '@/apis/AccountApi';

export default Vue.extend({
  data() {
    return {
      form: {
        username: '',
        email: '',
        password: '',
      },
      isValidUsername: null as null | boolean,
      usernameErrorText: this.$t('請輸入符合規則的使用者名稱'),

      isValidEmail: null as null | boolean,
      emailErrorText: this.$t('無效的 Email'),

      isValidPassword: null as null | boolean,
      passwordErrorText: this.$t('密碼不符合複雜度要求'),

    };
  },
  methods: {
    async handleSubmit() {
      if (this.isValidUsername && this.isValidEmail && this.isValidPassword) {
        try {
          const registerId = await AccountApi.register(this.form.username, this.form.email, this.form.password);
          this.$cookies.set('__registerId', registerId);
          this.$router.push('/finish-register');
        } catch (error) {
          this.handleRegisterError(error);
        }
      } else {
        this.isValidUsername = this.isValidUsername === true;
        this.isValidEmail = this.isValidEmail === true;
        this.isValidPassword = this.isValidPassword === true;
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleRegisterError(error: any) {
      if (error && error.response) {
        if (error.response.status === 409) {
          console.log(error.response);
          const { description } = error.response.data;
          if (description === 'Username has been used by an other account') {
            this.isValidUsername = false;
            this.usernameErrorText = this.$t('此使用者名稱已經被其他使用者註冊，請換一個名稱').toString();
            this.$notify({
              type: 'error',
              title: this.$t('錯誤').toString(),
              text: this.usernameErrorText,
            });
          }
          if (description === 'Email has been used by an other account') {
            this.isValidEmail = false;
            this.emailErrorText = this.$t('此 Email 已經被其他使用者註冊，請換一個 Email').toString();
            this.$notify({
              type: 'error',
              title: this.$t('錯誤').toString(),
              text: this.emailErrorText,
            });
          }
        }
      }
    },
    checkUsername() {
      const re = /^[a-zA-Z][a-zA-Z0-9]*$/;
      this.isValidUsername = re.test(this.form.username);
      this.usernameErrorText = this.$t('請輸入符合規則的使用者名稱').toString();
    },
    checkEmail() {
      const re = /^[^\s@]+@[^\s@]+$/;
      this.isValidEmail = re.test(this.form.email);
      this.emailErrorText = this.$t('無效的 Email').toString();
    },
    checkPassword() {
      const re = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,128}$/;
      this.isValidPassword = re.test(this.form.password);
      this.passwordErrorText = this.$t('密碼不符合複雜度要求').toString();
    },
  },
});
